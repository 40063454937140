export const getFilteredData = function (
  list,
  customer,
  isMedtenCustomer,
  isPart,
  key = "category_id"
) {
  if (list) {
    list.forEach((product) => {
      product.stock_usa = product.stock_us_irvine + product.stock_us_dallas;
    });
  }
  if (isMedtenCustomer) {
    return list;
  }
  if (customer.display_parts === true) {
    return list;
  }
  return list.filter((item) => {
    return !isPart(item[key]);
  });
};
